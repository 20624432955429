<template>
  <!-- 绩效-待评价记录 by lbj -->
  <div class="jxWhenMyAssesList">
    <van-nav-bar :title="$t('jxWhenMyAssesList.assTxt1')"
                 left-text
                 left-arrow
                 @click-left="onClickLeft" />
    <div class="overflow_div">
      <div class="assesList"
           v-for="(item, index) in assesList"
           :key="index">
        <div class="assesTitle">
          <div class="assesTitleLeft">{{ item.khplanname }}</div>
          <div class="statu"
               v-show="!item.multi"
               @click="item.multi = true">{{$t('jxWhenMyAssesList.assTxt2')}}</div>
          <div class="statu"
               v-show="item.multi"
               @click="item.multi = false">{{$t('jxWhenMyAssesList.assTxt3')}}</div>
        </div>
        <div class="emplist"
             v-for="(e, i) in item.children"
             :key="i">
          <van-checkbox v-model="e.checked"
                        v-show="item.multi"></van-checkbox>
          <div class="emplistBox"
               @click.stop="single(e, item)">
            <div class="viewZp">
              <img :src="e.picurl"
                   :alt="e.empname" />
            </div>
            <div class="msgbox">
              <div class="msgBoxLeft">
                <div class="empname">{{ e.empname }}</div>
                <div class="type">{{ e.checkflagname }}</div>
              </div>
              <div class="msgBoxRight">
                {{ e.checkstatus }}
                <svg-icon icon-class="right"></svg-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="btnbox">
          <van-button v-show="item.multi"
                      type="info"
                      size="small"
                      class="plbtn"
                      @click.stop="confirm(item)">{{$t('jxWhenMyAssesList.assTxt2')}}</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Notify } from "vant";
import { getUser360CheckList } from "@/api/wxjx.js";
import { checkPic } from "@/utils";
import { Dialog } from "vant";
export default {
  data () {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {};
    return {
      userInfo,
      leftToPath: "",
      choose: [],
      choAss: {
        khlanid: 0,
        khplanname: "",
        children: [],
      },
      assesList: [],
    };
  },
  beforeRouteEnter (to, from, next) {
    // console.log(from.fullPath, '1111111')
    next((vm) => {
      if (from.fullPath != "/AscLogin")
      {
        vm.leftToPath = from.fullPath;
      } else
      {
        location.href = localStorage.url;
      }
    });
  },
  created () {
    this.getUser360CheckList();
  },
  methods: {
    // 回退
    onClickLeft () {
      if (this.leftToPath)      {
        this.$router.push("/home");
      } else      {
        // 外站进入的地址
        const url = localStorage.getItem("url");
      }
    },
    // 获取360评价列表
    getUser360CheckList () {
      getUser360CheckList({ empid: this.userInfo.empid }).then((res) => {
        let arr = [];
        let khplanid = 0;
        res.data.forEach((e) => {
          if (e.khplanid != khplanid)
          {
            khplanid = e.khplanid;
            arr.push({
              khplanid: e.khplanid,
              khplanname: e.khplanname,
              multi: false,
              children: [],
            });
          }
          // console.log(khplanid)
          e.picurl = this.userInfo.cappicurl + "Cap_" + e.empid + ".jpg";
          arr[arr.length - 1].children.push(e);
        });
        this.assesList = arr;
        // 获取图片
        this.assesList.forEach((e) => {
          e.children.forEach((item) => {
            // 判断图片是否存在，不存在则替换为默认图片
            checkPic(item, "picurl");
          });
        });

        if (res.baseinfo[0].remark && sessionStorage.shoswms != "1")
        {
          Dialog.alert({
            message: res.baseinfo[0].remark
              .replace(/\\\\n/g, "<br>")
              .replace(/\\n/g, "<br>"),
            messageAlign: "left",
          }).then(() => {
            sessionStorage.setItem("shoswms", "1");
          });
        }
      });
    },
    // 单条
    single (e, item) {
      e.checked = true;
      this.choAss = item;
      this.choose = [e];
      this.goto();
    },
    // 多条
    confirm (item) {
      this.choAss = item;
      this.choose = [];
      item.children.forEach((e) => {
        if (e.checked)
        {
          this.choose.push(e);
        }
      });
      this.goto();
    },
    // 打开详细界面
    goto () {
      if (this.choose.length === 0)
      {
        Notify({ type: "warning", message: this.$t('jxWhenMyAssesList.assTxt4') });
      } else
      {
        this.choAss.num = this.choose.length
        console.log(this.choAss, 'detal')
        // 保存参数到 sessionStorage
        sessionStorage.setItem(
          "jxWhenMyAssesListDetail",
          JSON.stringify(this.choAss)
        );
        this.$router.push("/jxWhenMyAssesListDetail");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.jxWhenMyAssesList {
  height: 100vh;
  overflow: hidden;
  background: #eceaea;
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .overflow_div {
    overflow: auto;
    height: calc(100% - 96px);
    .assesList {
      background: #fff;
      margin-bottom: 30px;
      font-family: Source Han Sans CN;
      padding-bottom: 20px;
      .assesTitle {
        padding: 0 0.42667rem;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: space-between;
        height: 100px;
        border-bottom: 1px solid #ccc;

        font-weight: bold;
        .assesTitleLeft {
          font-size: 36px;
        }
        .statu {
          font-size: 32px;
          color: #2b8df0;
        }
      }

      .emplist {
        // padding-left: 30px;
        margin-bottom: 30px;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        padding: 0 0.42667rem;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: space-between;
        .van-checkbox {
          // padding-left: 20px;
          width: 70px;
        }
        .emplistBox {
          width: 100%;
          display: -webkit-box;
          display: -webkit-flex;
          display: flex;
          -webkit-box-align: center;
          -webkit-align-items: center;
          align-items: center;
          justify-content: space-between;

          .viewZp {
            width: 106px;
            height: 106px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 20px;
            display: flex;
            img {
              width: 100%;
            }
          }
          .msgbox {
            width: calc(100% - 106px);
            border-bottom: 1px solid #ccc;
            display: -webkit-box;
            display: -webkit-flex;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
            align-items: center;
            justify-content: space-between;
            .msgBoxLeft {
              padding: 20px 0;
              .empname {
                font-size: 36px;
                color: #333333;
              }
              .type {
                font-size: 24px;
                color: #707070;
              }
            }
            .msgBoxRight {
              font-size: 32px;
              color: #999;
            }
          }
        }
      }
      .btnbox {
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: space-between;
        .plbtn {
          width: 290px;
          height: 70px;
          margin: 0 auto;
          font-size: 36px;
        }
      }
    }
  }
}
</style>